window.__tnt || (window.__tnt = {});

__tnt.notify = (function(){
    var Notify = function(options) {
        return new Notify.lib.init(options);
    };

    var isValidUrl = function(str) {
        try {
            new URL(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    Notify.lib = Notify.prototype = {
        init: function(options) {
            if (!options) {options = {};}

            this.options = {};

            this.options.title = options.title;
            this.options.message = options.message;
            this.options.url = options.url;
            this.options.image = options.image;
            this.options.utm = options.utm;

            this.options.duration = options.duration || 6000;
            this.options.selector = options.selector;
            this.options.callback = options.callback || function(){};
            this.options.gravity = options.gravity == 'top' ? 'top' : 'bottom';
            this.options.position = options.position == 'left' ? 'left' : 'right';
            this.options.classes = options.classes || '';
            
            return this;
        },

        buildNotification: function() {
            if (!this.options) {
                throw 'Notify is not initialized';
            }
            if (!this.options.title) {
                throw 'Notification title is not defined';
            }
            if (!this.options.url && isValidUrl(this.options.title)) {
                this.options.url = this.options.title;
            }

            // Create the notification container element
            var divElement = document.createElement('div');
            divElement.classList.add('tnt-notification');
            divElement.classList.add('on');
            divElement.classList.add(this.options.position);
            divElement.classList.add(this.options.gravity);
            
            // Create the anchor element
            if (this.options.url) {
                var anchorElement = document.createElement('a');
                anchorElement.href = this.options.url;
                anchorElement.target = '_blank';
                // Add UTM params to URL
                if (this.options.utm) {
                    anchorElement.href += '?';
                    Object.keys(this.options.utm).forEach(function(val, i){
                        if (i != 0) {
                            anchorElement.href += '&';
                        }
                        anchorElement.href += val + '=' + this.options.utm[val];
                    }.bind(this));
                }
                divElement.appendChild(anchorElement);
            }
            
            // Create the image element
            if (this.options.image) {
                var imageElement = document.createElement('img');
                imageElement.src = this.options.image;
                divElement.appendChild(imageElement);
            }
            
            // Create the title element
            var titleElement = document.createElement('h4');
            titleElement.appendChild(document.createTextNode(this.options.title));
            divElement.appendChild(titleElement);
            
            // Create the message element
            if (this.options.message) {
                var messageElement = document.createElement('p');
                messageElement.appendChild(document.createTextNode(this.options.message));
                divElement.appendChild(messageElement);
            }

            // Create the dismiss element
            var dismissBtn = document.createElement('div');
            dismissBtn.classList.add('tnt-notification-dismiss');
            dismissBtn.innerHTML = '<i class="fas tnt-times"></i>';

            // Dismiss event listener
            dismissBtn.addEventListener('click', function(event) {
                event.stopPropagation();
                this.dismissNotification(event.target.closest('.tnt-notification'));
                window.clearTimeout(event.target.closest('.tnt-notification').timeOutValue);
            }.bind(this));
            divElement.appendChild(dismissBtn);
            
            return divElement;
        },

        showNotification: function() {
            // Create the DOM object for the notification
            var notifyElement = this.buildNotification();

            if (typeof this.options.selector === 'undefined') {
                rootElement = document.body;
            } else {
                rootElement = document.getElementById(this.options.selector);
            }

            // Validate if root element is present in DOM
            if (!rootElement) { throw 'Root element is not defined'; }

            // Add the DOM element
            rootElement.insertBefore(notifyElement, rootElement.firstChild);

            // Reposition
            Notify.reposition();

            notifyElement.timeOutValue = window.setTimeout(
                function(){
                    this.removeNotification(notifyElement);
                }.bind(this),
                this.options.duration
            );

            // Support function chaining
            return this;
        },

        dismissNotification: function(notifyElement) {
            // Hide element
            notifyElement.classList.remove('on');

            // Remove element from DOM after transition end
            window.setTimeout(function(){
                notifyElement.parentNode.removeChild(notifyElement);
                this.options.callback.call(notifyElement);
                Notify.reposition();
            }.bind(this), 400);
        },

        removeNotification: function(notifyElement) {
            // Hide element
            notifyElement.classList.remove('on');

            // Remove element from DOM after transition end
            window.setTimeout(function(){
                notifyElement.parentNode.removeChild(notifyElement);
                this.options.callback.call(notifyElement);

                // Register and dispatch custom event
                var event = new CustomEvent('notification-popup-removed', {
                    detail: {
                        note: {
                            title: this.options.title,
                            message: this.options.message,
                            url: this.options.url,
                            image: this.options.image
                        }
                    }
                });
                document.dispatchEvent(event);

                Notify.reposition();
            }.bind(this), 400);
        }
    };

    Notify.reposition = function() {
        var topLeftOffsetSize = {top:15, bottom:15};
        var topRightOffsetSize = {top:15, bottom:15};
        var offsetSize = {top:15, bottom:15};

        var allNotifications = document.getElementsByClassName('tnt-notification');
        var classUsed;

        // Modify the position of each notification element
        for (var i = 0; i < allNotifications.length; i++) {
            if (allNotifications[i].classList.contains('top')) {
                classUsed = 'top';
            } else {
                classUsed = 'bottom';
            }

            var height = allNotifications[i].offsetHeight;
            var offset = 15;
            var width = window.innerWidth > 0 ? window.innerWidth : screen.width;

            if (width <= 360) {
                allNotifications[i].style[classUsed] = offsetSize[classUsed] + 'px';
                offsetSize[classUsed] += height + offset;
            } else {
                if (allNotifications[i].classList.contains('left')) {
                    allNotifications[i].style[classUsed] = topLeftOffsetSize[classUsed] + 'px';
                    topLeftOffsetSize[classUsed] += height + offset;
                } else {
                    allNotifications[i].style[classUsed] = topRightOffsetSize[classUsed] + 'px';
                    topRightOffsetSize[classUsed] += height + offset;
                }
            }
        }
    };

    Notify.lib.init.prototype = Notify.lib;

    return Notify;
})();